/* File: App.css */

.app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  font-family: 'Roboto', sans-serif;
}

.app-header {
  background-color: #2c3e50;
  color: #ecf0f1;
  padding: 15px 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.logo a {
  text-decoration: none;
  color: #ecf0f1;
  font-size: 28px;
  font-weight: bold;
}

.main-nav ul {
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 10px 0 0 0;
  padding: 0;
}

.main-nav ul li {
  margin: 5px 10px;
}

.main-nav ul li a {
  text-decoration: none;
  color: #ecf0f1;
  font-size: 16px;
  transition: color 0.3s;
}

.main-nav ul li a:hover {
  color: #3498db;
}

.app-main {
  flex: 1;
  padding: 20px;
  background-color: #ecf0f1;
}

.app-footer {
  background-color: #2c3e50;
  color: #bdc3c7;
  text-align: center;
  padding: 10px 0;
}


@media (max-width: 768px) {
  .app-header {
    padding: 10px 20px;
  }

  .logo a {
    font-size: 24px;
  }

  .main-nav ul {
    flex-direction: column;
    align-items: center;
  }

  .main-nav ul li {
    margin: 5px 0;
  }

  .main-nav ul li a {
    font-size: 18px;
  }
}

